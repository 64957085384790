import React, { Component } from 'react';

const withCommonLandscape = (WrappedComponent) => {
  return class CommonLandscape extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLandscape: window.innerWidth <= 800 && window.innerWidth > window.innerHeight
      };
      this.handleResize = this.handleResize.bind(this);
    }

    handleResize() {
      this.setState({
        isLandscape: window.innerWidth <= 800 && window.innerWidth > window.innerHeight
      });
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    render() {
      if (this.state.isLandscape) {
        return <div style={{ marginTop: "20px", marginLeft: "20px" }}>Landscape mode is not supported. Please rotate your device for optimal viewing.</div>;
      } else {
        return <WrappedComponent {...this.props} />;
      }
    }
  };
};

export default withCommonLandscape;
